<template>
  <b-card
    :img-src="
      cartItem && cartItem.images
        ? cartItem.images.smallImage
        : cartItem.imageLink
    "
    img-alt="Card image"
    img-left
    class="mb-3"
  >
    <!-- <b-card-text class="card-text-sub">
      {{ cartItem ? cartItem.Brand : "" }}
    </b-card-text> -->
    <b-row>
      <b-col md="8">
        <b-card-text class="card-text-main">
          {{
            cartItem &&
            cartItem.description_summary &&
            cartItem.description_summary.length > 20
              ? cartItem && cartItem.Name
              : cartItem && cartItem.description_summary
          }}
          {{ cartItem ? cartItem.Quantity : cartItem.qty }}
        </b-card-text>
        <div>
          <b-card-text class="card-text-price"
            >₹ {{ cartItem && cartItem.Price }}
          </b-card-text>
        </div>
        <b-card-text class="mt-1"
          ><span class="order-provider-tag">Provider : </span>
          <span class="order-provider-name"> {{ cartItem.providerName }} </span>
          <span class="float-right"> </span>
        </b-card-text>
      </b-col>
      <b-col md="4" class="text-center pt-0">
        <div class="product-icon-circle pointer heart mb-3">
          <b-icon
            size="xl"
            icon="heart"
            @click="addToWishlist(cartItem)"
          ></b-icon>
        </div>
        <template v-if="$options.components['checkout-increment-decrement']">
          <checkout-increment-decrement
            :callCheckout="true"
            :cartItem="cartItem"
          />
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "cart-item",
  props: {
    cartItem: {
      default: () => {},
      type: Object,
    },
    callCheckout: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      cartData: (state) => state.checkout.cartData,
    }),
  },
  mounted() {},
  methods: {
    addToWishlist(data) {
      this.$bvModal
        .msgBoxConfirm("Do you want to move the item to Wishlist?", {
          title: "Please Confirm",
          size: "md",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((val) => {
          if (val) {
            this.$store
              .dispatch("wishlist/addToWishlist", data)
              .then(async (res) => {
                console.log(res);
                this.$swal({
                  title: "Success",
                  text: "Added to your Wishlist",
                  type: "success",
                  timer: 1000,
                });
                await this.$store.dispatch("checkout/removeItem", {
                  cartItem: this.cartItem,
                  callApi: this.callCheckout,
                });
                console.log(this.callCheckout, "boolean check");
                if (this.callCheckout)
                  await this.$store.dispatch("checkout/checkoutFinal");
              });
          }
        });
    },
  },
};
</script>
<style scoped>
.card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
  margin: 5px;
  height: 120px;
  width: 120px;
  object-fit: contain;
}
.product-icon-circle {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  width: 40px;
  height: 40px;
  font-size: 25px;
}

.heart {
  color: #ff6d59;
}
.card-text-main {
  font-size: 18px;
  line-height: 20px;
}
.card-text-sub {
  font-size: 16px;
  line-height: 10px;
  color: #a4a4a4;
  margin-left: 2px;
}
.card-text-price {
  font-size: 26px;
  line-height: 30px;
}
.order-provider-tag {
  font-size: 14px;
}
.order-provider-name {
  font-size: 14px;
  text-decoration-line: underline;
  color: #3977d3;
}
.card {
  border: none;
  font-weight: 500;
  font-style: normal;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image: initial initial initial;
}

@media (max-width: 768px) {
  .inc-dec-mobile-view {
    margin-bottom: 15px;
  }
}
</style>
